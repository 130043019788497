import axiosconfig from "./axiosconfig";
import qdaxiosconfig from "./qd-axiosconfig";
export const fetchNewsAnalysis = async ({ doc_id, text, auth_key }) => {
    try {
        const response = await axiosconfig.post(`/news_analysis`, {
            doc_id,
            text,
            auth_key,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data from news_analysis:', error);
        throw error;
    }
};

export const fetchClaimVerification = async ({ doc_id, text, auth_key }) => {
    try {
        const response = await axiosconfig.post(`/claim_verification`, {
            doc_id,
            text,
            auth_key,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data from claim_verification:', error);
        throw error;
    }
};

export const fetchQueryDecomposition = async ({ prompt }) => {
    try {
        const response = await qdaxiosconfig.post(`/decompose_prompt`, {
            "prompt": prompt,
            "use_schema": false
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data from decompose_prompt:', error);
        throw error;
    }
};