import React from 'react';
import Layout from "../../layouts/Layout";
import {Grid, Box, Typography, Card, CardContent, alpha} from "@mui/material";
import theme from "../../theme/AppTheme";
import {Link} from "react-router-dom";

import bioentity_tagging from '../../assets/images/bioentity_tagging.png';
import citance_analysis from '../../assets/images/citance_analysis.png';
import citation_analysis from '../../assets/images/citation_analysis.png';
import claim_evidence_analysis from '../../assets/images/claim_evidence_analysis.png';
import claim_verification from '../../assets/images/claim_verification.png';
import taxonomy_mapper from '../../assets/images/taxonomy_mapper.png';
import collaboration_analysis from '../../assets/images/collaboration_analysis.png';
import field_of_science_analysis from '../../assets/images/field_of_science_analysis.png';
import interdisciplinarity from '../../assets/images/interdisciplinarity.png';
import news_claims_analysis from '../../assets/images/news_claims_analysis.png';
import publication_analysis from '../../assets/images/publication_analysis.png';
import research_artifact_extraction from '../../assets/images/research_artifact_extraction.png';
import sdg_classifier from '../../assets/images/sdg_classifier.png';
import github_logo from '../../assets/logo/github-mark.png';
import github_logo_gray from '../../assets/logo/github-mark-gray.png';
import docker_hub_logo from '../../assets/logo/docker-mark-blue.png';
import gradio_logo from '../../assets/logo/gradio-icon-logo.png';
const Toolkit = () => {
    const toolkitData = [
        {
            image: bioentity_tagging,
            to: '/not-available',
            title: 'Bio-entity tagging',
            description: 'Extracts and annotates health publications based on bio entities like genes or proteins. Helps identify and analyze relevant biological information.',
            github_link: '/not-available',
            docker_hub_link: '/not-available',
            gradio_link: '/not-available'
        },
        {
            image: field_of_science_analysis,
            to: '/not-available',
            title: 'Field of Science (FoS) classification',
            description: 'Uses a hierarchical classifier to assign one or more labels to a publication based on its content and metadata. The hierarchical system consists of 6 levels, with the first 3 levels being standard in the literature. This approach allows to adhere to the well-established taxonomies in scientific literature while also capturing the dynamics of scientific developments at levels 5 and 6, where new topics emerge and some fade out.',
            github_link: 'https://github.com/iNoBo/scinobo-fos-classification',
            docker_hub_link: 'https://hub.docker.com/repository/docker/intelligencenoborders/scinobo-fos-classification/general',
            gradio_link: '/live-demo?HFSpace=inobo-scinobo-fos-classification.hf.space'
        },
        {
            image: taxonomy_mapper,
            to: '/taxonomy-mapper',
            title: 'Taxonomy Mapper',
            description: 'Given a phrase or a sentence related to a Field of Science, identify other relevant Fields of Science.',
            github_link: '/not-available',
            docker_hub_link: '/not-available',
            gradio_link: '/live-demo?HFSpace=inobo-scinobo-taxonomy-mapper.hf.space'
        },
        {
           image: sdg_classifier,
            to: '/not-available',
            title: 'Sustainable Development Goal (SDG) classifier',
            description: 'Categorizes publications and artifacts based on the Sustainable Development Goals (SDGs). It is a multi-label classifier that assigns multiple labels to a publication, allowing researchers to align their work with specific SDGs.',
            github_link: 'https://github.com/iNoBo/scinobo-sdg-classification',
            docker_hub_link: 'https://hub.docker.com/repository/docker/intelligencenoborders/scinobo-sdg-classifier/general',
            gradio_link: '/live-demo?HFSpace=inobo-scinobo-sdg-classification.hf.space'
        },
        {
            image: research_artifact_extraction,
            to: '/not-available',
            title: 'Research Artifact Extraction',
            description: 'Extracts mentions and references of research artifacts from publications. The type of artifact extracted depends on the specific domain, such as software (computer science), surveys (social sciences, humanities), genes or proteins (biomedical sciences). The goal is to accurately extract all mentions and find all the metadata that coexist in the publication.',
            github_link: 'https://github.com/iNoBo/scinobo-raa',
            docker_hub_link: 'https://hub.docker.com/repository/docker/intelligencenoborders/scinobo-raa/general',
            gradio_link: '/live-demo?HFSpace=inobo-scinobo-research-artifact-analysis.hf.space'
        },
        {
            image: interdisciplinarity,
            to: '/not-available',
            title: 'Interdisciplinarity Analysis',
            description: 'Explores research classification at various levels and highlights interdisciplinary aspects. Helps identify collaborations across different fields.',
            github_link: '/not-available',
            docker_hub_link: '/not-available',
            gradio_link: '/not-available'
        },
        {
            image: collaboration_analysis,
            to: '/not-available',
            title: 'Collaboration Analysis',
            description: 'Analyzes collaborations between fields and identifies multidisciplinary papers. Provides insights and indicators to help researchers understand the interdisciplinarity of a publication and joint efforts of researchers from different disciplines.',
            github_link: '/not-available',
            docker_hub_link: '/not-available',
            gradio_link: '/not-available'
        },
        {
            image: citation_analysis,
            to: '/not-available',
            title: 'Citation Analysis',
            description: 'Aggregates conclusions from various sources, aiding researchers in conducting surveys on citation analysis. Facilitates a comprehensive understanding of how the scientific community adopts or builds upon previous findings.',
            github_link: '/not-available',
            docker_hub_link: '/not-available',
            gradio_link: '/not-available'
        },
        {
            image: claim_evidence_analysis,
            to: '/not-available',
            title: 'Scientific Claim and Evidence analysis',
            description: 'Detects claims and evidence in scientific publications, providing insights to analyze disinformation and misinformation. Helps identify if news statements are grounded in scientific terms and can collect claims and conclusions from different papers.',
            github_link: '/not-available',
            docker_hub_link: '/not-available',
            gradio_link: '/not-available'
        },
        {
            image: citance_analysis,
            to: '/not-available',
            title: 'Citance Analysis',
            description: 'Analyzes statements on previous findings in a specific topic. Assesses the scientific community\'s adoption or expansion of these conclusions, helping researchers understand the endorsement or acceptance of previous research.',
            github_link: 'https://github.com/iNoBo/scinobo-citance-analysis',
            docker_hub_link: 'https://hub.docker.com/repository/docker/intelligencenoborders/scinobo-citance-analysis/general',
            gradio_link: '/live-demo?HFSpace=inobo-scinobo-citance-analysis.hf.space'
        },
        {
            image: news_claims_analysis,
            to: '/claim-analysis',
            title: 'News Claim Analysis',
            description: 'Analyze news articles in Health, Nutrition and Climate Change. Extract the main claim, claimer and claim object.',
            github_link: '/not-available',
            docker_hub_link: '/not-available',
            gradio_link: '/live-demo?HFSpace=inobo-scinobo-claim-verification.hf.space'
        },
        {
            image: claim_verification,
            to: '/claim-verification',
            title: 'Claim Verification',
            description: 'Based on a claim in Health, Nutrition or Climate Change, retrieve scientific claims that support, refute or are neutral to the claim.',
            github_link: '/not-available',
            docker_hub_link: '/not-available',
            gradio_link: '/live-demo?HFSpace=inobo-scinobo-claim-verification.hf.space'
        },
        {
            image: publication_analysis,
            to: '/text-simplification',
            title: 'Text Simplification',
            description: 'Processes texts in the Climate Change and Health domain providing simplified version that are mor understandable for domain non-experts.',
            github_link: 'https://gitlab.com/dfki-han-ts/simplification-service',
            docker_hub_link: '/not-available',
            gradio_link: '/text-simplification'
        }
    ];

    const styles = {
        card: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: 4,
            boxShadow: `0 5px 10px ${alpha(theme.palette.common.black, 0.16)}`,
            border: 'none',
            padding: theme.spacing(3)
        },
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 0,
            height: '100%',
            alignItems: 'center',
            '&:last-child': {
                paddingBottom: 0
            }
        }
    }

    return (
        <Layout>
            <Box mb={3}><Typography variant={'h4'} color={'primary'} textAlign={'center'}>Toolkit</Typography></Box>
            <Grid container spacing={3}>
                {toolkitData.map((service, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <Card sx={styles.card}>
                            <CardContent sx={styles.cardContent}>
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <Box mb={3}>
                                        <img src={service.image} alt={`Service ${index + 1}`} width={100} height={100}/>
                                    </Box>
                                    <Typography gutterBottom textAlign={'center'} variant={'h5'} style={{ color: theme.palette.primary.main }}>
                                        {service.title}
                                    </Typography>
                                    <Typography variant={"body2"} color={"text.secondary"} textAlign={'justify'}>
                                        {service.description}
                                    </Typography>
                                </Box>
                                <Box mt={7} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    {service.github_link !== '/not-available' ? (
										<Link to={service.github_link}>
											<img 
												style={{ marginLeft: 10, marginRight: 10 }} 
												width={50} 
												height={50} 
												src={github_logo} 
												alt={'GitHub Logo'} 
											/>
										</Link>
									) : (
										<img 
											style={{ marginLeft: 10, marginRight: 10, filter: 'grayscale(100%)' }} 
											width={50} 
											height={50} 
											src={github_logo_gray} 
											alt={'GitHub Logo'} 
										/>
									)}
									{service.docker_hub_link !== '/not-available' ? (
										<Link to={service.docker_hub_link}>
											<img 
												style={{ marginLeft: 10, marginRight: 10 }} 
												width={50} 
												height={50} 
												src={docker_hub_logo} 
												alt={'Docker Hub Logo'} 
											/>
										</Link>
									) : (
										<img 
											style={{ marginLeft: 10, marginRight: 10, filter: 'grayscale(100%)' }} 
											width={50} 
											height={50} 
											src={docker_hub_logo} 
											alt={'Docker Hub Logo'} 
										/>
									)}
									{service.gradio_link !== '/not-available' ? (
										<Link to={service.gradio_link}>
											<img 
												style={{ marginLeft: 10, marginRight: 10 }} 
												width={50} 
												height={50} 
												src={gradio_logo} 
												alt={'Gradio Logo'} 
											/>
										</Link>
									) : (
										<img 
											style={{ marginLeft: 10, marginRight: 10, filter: 'grayscale(100%)' }} 
											width={50} 
											height={50} 
											src={gradio_logo} 
											alt={'Gradio Logo'} 
										/>
									)}
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Layout>
    )
};
export default Toolkit;