import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { Box, Chip, Typography, CircularProgress, Backdrop } from '@mui/material';

import SearchField from "../../components/fields/SearchField";
import Banner from "../../components/banner/Banner";
import ideaIcon from '../../assets/icons/idea.svg';
import Layout from "../../layouts/Layout";

const HomeComponent = () => {
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const isBackBoneEnabled = process.env.REACT_APP_BACKBONE_ENABLED === 'true' || false;

    const handleSearch = async () => {
        setLoading(true);
        try {
            // Simulate a search operation
            navigate('/response', { state: { searchText } });
        } catch (error) {
            console.error('Error handling search:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout>
            <Banner />
            <Box sx={{ marginBottom: 7 }} />
            {isBackBoneEnabled ? (
                <>
                    <Box display={'flex'} justifyContent={'center'} sx={{ marginBottom: 7 }}>
                        <Typography variant={'h4'} sx={{ color: '#4E5F68' }}>Start exploring now</Typography>
                    </Box>
                    <Box sx={{ marginBottom: 7 }} />
                    <SearchField 
                        searchText={searchText} 
                        setSearchText={setSearchText} 
                        setLoading={setLoading} 
                        loading={loading} // Pass loading state as a prop
                    />
                    <Box sx={{ marginBottom: 7 }} />
                    {loading ? (
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    ) : (  
                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <Box display={'flex'} alignItems={'end'} marginBottom={4}>
                                <Typography variant={'body1'} sx={{ color: '#51616B' }}>Try the suggested</Typography>
                                <img alt={'suggestions'} src={ideaIcon} width={44} height={34} />
                            </Box>
                            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                <Chip variant={'outlined'} label={'claim analysis in news article'} onClick={() => navigate('/claim-analysis')} sx={{ marginBottom: 3 }} />
                                <Chip variant={'outlined'} label={'claim verification'} onClick={() => navigate('/claim-verification')} />
                            </Box>
                        </Box>
                    )}
                </>
            ) : (
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{ marginBottom: 7 }}>
                    <Typography variant={'h4'} sx={{ color: '#4E5F68', marginBottom: 3 }}>
                        Demonstration of the SciNoBo Assistant:
                    </Typography>
                    {/* Video section */}
                    <video style={{ width: '65vw', height: 'auto' }} controls>
                        <source src="/videos/scinobo_assistant_demo.mp4" type="video/mp4" />
                        <track src="/videos/scinobo_assistant_demo.vtt" kind="subtitles" srclang="gr" label="English" default />
                        Your browser does not support this video.
                    </video>
                    {/* Text below the video */}
                    <Typography variant={'h6'} sx={{ color: '#4E5F68', marginTop: 3 }}>
                        The SciNoBo Assistant will be available soon.
                    </Typography>
                    <Typography variant={'h6'} sx={{ color: '#4E5F68', marginTop: 3 }}>
                        To browse our services individually, please click <Link to={'/toolkit'}>here</Link>.
                    </Typography>
                </Box>
            )}
        </Layout>
    );
};

export default HomeComponent;
