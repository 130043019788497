import React, { useState } from 'react';
import { alpha, Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import theme from "../../theme/AppTheme";

import articlesImg from '../../assets/images/articles.svg';
import { Close } from "@mui/icons-material";
const Banner = () => {

    const [isBannerVisible, setBannerVisible] = useState(false);
    const handleCloseClick = () => {
        setBannerVisible(true);
    };

    const isSm = useMediaQuery('(max-width:970px)');
    // const handleButtonClick = () => {
    //     const url = '#';
    //     // Open the URL in a new tab
    //     window.open(url, '_blank');
    // };

    const styles =  {
        wrapper: {
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0 5px 10px ${alpha(theme.palette.common.black, 0.16)}`,
            position: 'relative',
            padding: 4,
            borderRadius: 6,
            display: isBannerVisible ? 'none' : 'block',
        },
        whiteButton: {
            borderColor: theme.palette.common.white,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            padding: '8px 22px',
            color: theme.palette.common.white,
            textTransform: 'none',
            fontSize: 15,
            fontWeight: 400,
            '&:hover': {
                borderColor: theme.palette.common.white,
                borderStyle: 'solid',
                borderWidth: 1,
                backgroundColor: '#0F6686'
            }
        }
    }

    return (
        <Box sx={styles.wrapper}>
            <Typography variant='h6' color={theme.palette.common.white}>AI assistant for science discovery and analysis</Typography>
            <Typography sx={{color: '#E4E4E4', fontSize: 16}}>The SciNoBo Research Assistant will be available soon...</Typography>
            <Box sx={{paddingTop: 5}}>
                {/*{hasMore && (*/}
                {/*    <Button variant={'outlined'} sx={styles.whiteButton} onClick={handleButtonClick}>Learn more</Button>*/}
                {/*)}*/}
            </Box>
            {isSm ? (
                <Box sx={{position: 'absolute', right: -24, bottom: -56, display: 'none'}}>
                    <img src={articlesImg} alt={'articles'} width={'auto'} height={'auto'} />
                </Box>
            ) : (
                <Box sx={{position: 'absolute', right: -24, bottom: -56, display: 'block'}}>
                    <img src={articlesImg} alt={'articles'} width={'auto'} height={'auto'} />
                </Box>
            )}
            <Box sx={{position: 'absolute', right: 16, top: 16, zIndex: 1}}>
                <IconButton aria-label="close" size={'small'} sx={styles.iconButton} onClick={handleCloseClick}>
                    <Close />
                </IconButton>
            </Box>
        </Box>
    );
};
export default Banner;
