import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import DefaultLayout from "../layouts/DefaultLayout";
import Home from "../pages/home/Home";
import ClaimAnalysis from "../pages/claim-analysis/ClaimAnalysis";
import ClaimVerification from "../pages/claim-verification/ClaimVerification";
import TextSimplification from "../pages/text-simplification/TextSimplification";
import About from "../pages/about/About";
import Toolkit from "../pages/toolkit/Toolkit";
import LiveDemo from "../pages/live-demo/liveDemo"
import Research from "../pages/research/Research";
import Documentation from "../pages/documentation/Documentation"
import Contact from "../pages/contact/Contact"
import Response from "../pages/response/Response";
import ResponseTS from "../pages/response/ResponseTS";
import NotAvailable from "../pages/not-available/NotAvailable";
const AppRouter = () => {
    const router = createBrowserRouter([
        {
            // parent route component
            element: <DefaultLayout />,
            // child route components
            children: [
                { path: "/", element: <Home /> },
                { path: "/claim-analysis", element: <ClaimAnalysis /> },
                { path: "/claim-verification", element: <ClaimVerification /> },
                { path: "/text-simplification", element: <TextSimplification /> },
                { path: "/response", element: <Response /> },
                { path: "/response-ts", element: <ResponseTS /> },
                { path: "/about", element: <About /> },
                { path: "/toolkit", element: <Toolkit /> },
                { path: "/live-demo", element: <LiveDemo /> },
                { path: "/research", element: <Research /> },
                { path: "/documentation", element: <Documentation /> },
                { path: "/contact", element: <Contact /> },
                { path: "/not-available", element: <NotAvailable /> }
            ],
        },
    ]);

    return (
        <RouterProvider router={router} />
    );
}
export default AppRouter;
