import React from 'react';
import { Box, Typography } from '@mui/material';

import Layout from "../../layouts/Layout";
import CardSearch from "../../components/cards/CardSearch";
const ClaimAnalysis = () => {
    return (
        <>
            <Layout sx={{height: '100vh'}}>
                <Box marginTop={12}>
                    <Box marginBottom={3}>
                        <Typography variant={'h4'} sx={{fontSize: 30, color: '#647684'}}>I want to perform claim analysis in a news article by  providing the article</Typography>
                    </Box>
                    <CardSearch text={'Please provide the article that you want to analyze'} pageType={'news_analysis'}/>
                </Box>
            </Layout>
        </>
    );
};
export default ClaimAnalysis;
  