import React from 'react';
import { Box, Button, Typography } from "@mui/material";
import {Link} from "react-router-dom";

import Layout from "../../layouts/Layout";
import serviceImg from '../../assets/images/service.svg';
const NotAvailable = () => {
    return (
        <Layout>
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box>
                    <img src={serviceImg} alt={'Service not available'} width={500} height={'100%'}/>
                </Box>
                <Box mt={5}>
                    <Typography variant={'h6'}>The service is not available yet.</Typography>
                </Box>
                <Box mt={2}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        component={Link}
                        to={'/toolkit'}
                    >
                        Go back
                    </Button>
                </Box>
            </Box>
        </Layout>
    )
};
export default NotAvailable;