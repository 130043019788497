import React from 'react';
import Layout from "../../layouts/Layout";

const WorkInProgress = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
  };

  const textStyle = {
    fontSize: '24px',
    color: '#555',
    marginBottom: '20px',
  };

  const emojiStyle = {
    fontSize: '40px',
  };

  return (
    <Layout>
        <div style={containerStyle}>
        <div style={textStyle}>🚧 Work in Progress 🚧</div>
        <div style={emojiStyle}>🔧</div>
        </div>
    </Layout>
  );
};

export default WorkInProgress;
