import { alpha } from '@mui/material';
import { createTheme } from '@mui/material/styles'

const palette = {
  common: {
    black: '#000000',
    white: '#ffffff'
  },
  primary: {
    main: '#117499',
    dark: '#116786',
    light: '#1887B0'
  },
  secondary: {
    main: '#5AA8D0'
  },
  error: {
    main: '#C15959',
    light: '#de6464'
  },
  success: {
    main: '#5CBCA9',
    light: '#79C5B6'
  },
  info: {
    main: '#50B8C1'
  },
  warning: {
    main: '#DD9B2B'
  },
  text: {
    primary: '#0E2D3C',
    secondary: alpha('#000000', 0.54),
    disabled: alpha('#000000', 0.38)
  },
  grey: {
    100: alpha('#ffffff', 0.38),
    200: alpha('#ffffff', 0.54),
    300: alpha('#ffffff', 0.7),
    400: alpha('#000000', 0.04),
    500: alpha('#000000', 0.12),
    600: alpha('#000000', 0.38),
    700: alpha('#000000', 0.54),
    800: alpha('#000000', 0.87)
  },
  background: {
    paper: '#ffffff',
    default: alpha('#000000', 0.04)
  },
  divider: alpha('#000000', 0.12)
};

const typography = {
  fontFamily: ['"Source Sans 3"', 'sans-serif'].join(","),
  h1: {
    fontSize: '100px',
    fontWeight: 300,
    lineHeight: '120px',
    letterSpacing: '0px'
  },
  h2: {
    fontSize: '62px',
    fontWeight: 300,
    lineHeight: '74px',
    letterSpacing: '0px'
  },
  h3: {
    fontSize: '50px',
    fontWeight: 400,
    lineHeight: '60px',
    letterSpacing: '0px'
  },
  h4: {
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: '42px',
    letterSpacing: '0px'
  },
  h5: {
    fontSize: '25px',
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: '0px'
  },
  h6: {
    fontSize: '21px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0px'
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '0px'
  },
  body2: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '0px'
  },
  caption: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0px'
  },
  overline: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '12px',
    letterSpacing: '0px',
    textTransform: 'uppercase'
  }
};

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
          body: {
            backgroundColor: palette.common.white
          }
      }
  },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {

        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 24px',
          borderRadius: 20,
          boxShadow: 'none',
          border: 'none',
          textTransform: 'none',
          fontSize: typography.body1.fontSize,
          fontWeight: typography.body2.fontWeight,
          '&:hover': {
            boxShadow: 'none',
            border: 'none',
          }
        },
        text: {
          '&:hover': {
           backgroundColor: palette.background.default
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: 36,
          height: 36,
          backgroundColor: palette.primary.main,
          padding: 6,
          '&:hover': {
            backgroundColor: palette.primary.dark,
          },
          '& .MuiSvgIcon-root': {
            '& path': {
              color: palette.common.white
            }
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        '& .MuiInputBase-root': {
          '& .MuiOutlinedInput-root': {
            borderRadius: 12,
            color: palette.text.secondary
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          color: palette.text.primary,
          fontWeight: typography.body1.fontWeight,
          fontSize: typography.body1.fontSize,
          padding: "24px !important",
          '& .MuiAutocomplete-input': {
            padding: '0px !important'
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.primary.main
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
            },
          }
        },
        notchedOutline: {
          borderColor: palette.divider,
          '&:hover': {
            borderColor: palette.primary.main
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderColor: palette.primary.main
          }
        },
        paper: {
          border: 'grey',
          boxShadow: `0 5px 10px ${alpha(palette.common.black, 0.12)}`,
          borderTopLeftRadiusRadius: 0,
          borderTopRightRadiusRadius: 0,
          borderBottomLeftRadiusRadius: 12,
          borderBottomRightRadiusRadius: 12,
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:hover': {
            backgroundColor: palette.background.default,
            '& .MuiListItemIcon-root': {
              color: palette.primary.main
            },
            '& .MuiListItemText-root': {
              color: palette.primary.main,
              fontSize: typography.body2.fontSize,
              fontWeight: typography.body2.fontWeight
            }
          },
          '&.Mui-selected': {
            backgroundColor: palette.background.default,
            '& .MuiListItemIcon-root': {
              color: palette.primary.main
            },
            '& .MuiListItemText-root': {
              color: palette.primary.main,
            },
            '&:hover': {
              backgroundColor: palette.background.default
            },
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          marginLeft: 24,
          marginRight: 24,
          marginTop: 54
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
          paddingRight: 16
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: typography.body2.fontSize,
          fontWeight: typography.body2.fontWeight,
          color: palette.text.secondary
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingTop: 40
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          boxShadow: `0 5px 10px ${alpha(palette.common.black, 0.16)}`,
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
            borderColor: palette.divider
        },
        label: {
          fontSize: 14
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          padding: 32,
          borderRadius: 20,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: palette.divider
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
          borderRadius: 40,
          '&:before': {
            borderBottom: 0
          },
          '&:after': {
            borderBottom: 0
          },
          '&:hover': {
            '&:not(.Mui-disabled, .Mui-error)': {
              '&:before': {
                borderBottom: 0
              },
              '&:after': {
                borderBottom: 0
              },
            }
          },
          '&.Mui-focused': {
            borderBottom: 0
          },
          '&.Mui-error': {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: palette.error.main
          }
        },
        input: {
          padding: '16px 36px',
          fontSize: 18,
          fontWeight: typography.h5.fontWeight,
          color: palette.text.secondary,
          '&:-webkit-autofill': {
            padding: '16px 36px',
            fontSize: 18,
            fontWeight: typography.h5.fontWeight,
            color: palette.text.secondary,
            backgroundColor: palette.background.default,
            borderRadius: 40,
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#364954',
          borderRadius: 8,
          fontWeight: 500
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: `0 5px 10px ${alpha(palette.common.black, 0.16)}`,
          borderRadius: 10
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          left: '65%'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          boxShadow: `0 5px 10px ${alpha(palette.common.black, 0.16)}`,
          padding: 16,
          color: palette.common.white
        },
        standardSuccess: {
          backgroundColor: palette.success.light,
        },
        standardError: {
          backgroundColor: palette.error.light,
        },
        icon: {
          '& .MuiSvgIcon-root': {
            color: palette.common.white
          }
        },
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: `0 5px 10px ${alpha(palette.common.black, 0.16)}`,
          paddingTop: 16,
          paddingBottom: 16,
          '&:before': {
            display: 'none'
          },
          '&:first-of-type': {
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12
          },
          '&:last-of-type': {
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12
          }
        }
      }
    }
  },
});
export default theme;
