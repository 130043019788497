import axiosconfig from "./dfkiaxiosconfig";

export const fetchTextSimplification = async ({ doc_id, text, auth_key }) => {
    try {
        // const response = await axiosconfig.post(`/simply/analyze_text?model=lsbert`, text);
        const response = await axiosconfig.post(`han?model=lsbert`, text);
        console.log(response)
        return response.data;
    } catch (error) {
        console.error('Error fetching data from claim_verification:', error);
        throw error;
    }
};


