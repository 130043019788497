import React from 'react';
import Layout from "../../layouts/Layout";
import {Box, Typography} from "@mui/material";
const About = () => {
    return (
        <Layout>
            <Box mb={3}><Typography variant={'h4'} color={'primary'} textAlign={'center'}>About SciNoBo</Typography></Box>
            <Typography component={'div'}>
                <b><i>SciNoBo</i></b> is a <b>toolkit of open AI-enabled agents</b>, controlling <b>fit-for-purpose tools and services</b> empowered by <b>open source, base Large Language Models (LLMs)</b>, transforming the way stakeholders exploit research outputs within and across research disciplines.  All your <b>interactions</b> with Science will be mediated by <b>SciNoBo AI assistants</b> and specialized applications that, given a task request, autonomously retrieve the agents they need to complete the task, and then execute it. SciNoBo Assistants are also capable of leveraging <b>external agents</b>, data APIs and <b>reasoning strategies</b> facilitating scientific multi-disciplinary cooperation.
            </Typography>
        </Layout>
    )
};
export default About;