import React from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchQueryDecomposition } from '../../apis/api';
import { TextField, IconButton, InputAdornment, Autocomplete, CircularProgress } from '@mui/material';
import { Search } from '@mui/icons-material';

const SearchField = ({ searchText, setSearchText, setLoading, loading }) => {
			  
    const navigate = useNavigate();
    const suggestions = [
        // { title: 'I want to perform claim analysis in a news article', route: '/claim-analysis' },
        // { title: 'I want to perform claim verification in an article', route: '/claim-verification' },
    ];

    const handleSearch = async () => {
        setLoading(true);
        console.log('Searching for:', searchText);
        		   
        try {
            // Fetching query decomposition results
            const decompositionData = await fetchQueryDecomposition({
                prompt: searchText,
            });
            navigate('/response', { state: { data: decompositionData, pageType: "search", searchText: searchText } });
        } catch (error) {
            console.error('Error handling search:', error);
            navigate('/response');
        } finally {
            setLoading(false);
        }
    };

    const styles = {
        iconButton: {
            position: 'absolute',
            bottom: 24,
            right: 24
        }
    };

    return (
        <Autocomplete
            freeSolo
            id="autocomplete-search-field"
            disableClearable
            options={suggestions.map((option) => option.title)}
            onInputChange={(event, value) => setSearchText(value)}
            onChange={(event, value) => {
                // Trigger search when a suggestion is selected
                setSearchText(value);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label=""
                    multiline
                    placeholder={'Search or ask a question...'}
                    rows={4}
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        endAdornment: (
                            <InputAdornment position="end">
                                 {loading ? undefined : 
                                 <IconButton onClick={handleSearch} aria-label="search" size={'small'} sx={styles.iconButton}>
                                    <Search />
                                </IconButton>}
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default SearchField;
