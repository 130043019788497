import React from 'react';
import Layout from "../../layouts/Layout";
import { Container, Typography, Link, Grid, Card, CardContent, CardMedia } from '@mui/material';
import github_logo from '../../assets/logo/github-mark.png';
import docker_hub_logo from '../../assets/logo/docker-mark-blue.png';
import gradio_logo from '../../assets/logo/gradio-icon-logo.png';

const platforms = [
  {
    name: 'GitHub',
    logo: github_logo,
    link: 'https://github.com/iNoBo',
    alt: 'GitHub Logo',
  },
  {
    name: 'Docker Hub',
    logo: docker_hub_logo,
    link: 'https://hub.docker.com/u/intelligencenoborders',
    alt: 'Docker Hub Logo',
  },
  {
    name: 'Hugging Face',
    logo: gradio_logo,
    link: 'https://huggingface.co/iNoBo',
    alt: 'Hugging Face Logo',
  },
];

const Contact = () => {
  return (
    <Layout>
      <Container maxWidth="md" style={{ textAlign: 'center', marginTop: '50px' }}>
        <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold' }}>
          Connect with Our Research Team
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph style={{ textAlign: 'justify', marginBottom: '40px' }}>
          We welcome you to explore our work, collaborate, or just reach out to discuss ideas. Our team is active on various platforms, and we look forward to engaging with you.
        </Typography>
        <Typography variant="h6" gutterBottom style={{ marginBottom: '20px', fontWeight: 'bold' }}>
          Find us on:
        </Typography>
        <Grid container spacing={3} justifyContent="center" alignItems="stretch">
          {platforms.map((platform, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  padding: '20px',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <Link href={platform.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                  <CardMedia
                    component="img"
                    image={platform.logo}
                    alt={platform.alt}
                    sx={{
                      width: '100px',
                      height: '100px',
                      margin: '20px auto',
                      objectFit: 'contain',
                    }}
                  />
                  <CardContent sx={{ paddingTop: 0 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                      {platform.name}
                    </Typography>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography variant="body2" color="textSecondary" paragraph style={{ marginTop: '40px', textAlign: 'center' }}>
          Thank you for your interest in our work!
        </Typography>
      </Container>
    </Layout>
  );
};

export default Contact;
