import React from 'react';
import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
const DefaultLayout = () => {
  return (
    <>
      <main
        style={{
            minHeight: 'calc(100% - 100vh)',
            backgroundColor: 'white',
            //height: '100vh'
            position: 'relative'
        }}
      >
        <Container maxWidth={'lg'} style={{ height: '100vh' }}>
            <Outlet />  
        </Container>
      </main>
    </>
  )
};
export default DefaultLayout;