// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans 3", sans-serif;
  background-color: #fff;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/body.scss","webpack://./src/assets/styles/index.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;EACA,wCAAA;EACA,sBAAA;EACA,aAAA;ACCF","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: 'Source Sans 3', sans-serif;\n  background-color: #fff;\n  height: 100vh;\n}\n","body {\n  margin: 0;\n  padding: 0;\n  font-family: \"Source Sans 3\", sans-serif;\n  background-color: #fff;\n  height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
