import React from 'react';
import { Alert, Snackbar } from '@mui/material';
const SnackbarCopy = ({isCopied, handleCloseSnackbar}) => {
    return (
        <>
            <Snackbar
                open={isCopied}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert severity="success">
                    Claim copied to clipboard
                </Alert>
            </Snackbar>
        </>
    )
};
export default SnackbarCopy;