import React from 'react';
import {Link, useLocation} from "react-router-dom";

import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
} from '@mui/material';

import {AutoStories, Home, Info, MiscellaneousServices, Article, ContactSupport} from '@mui/icons-material';
import logo from '../../assets/logo/logo.svg';
import theme from "../../theme/AppTheme";

// Import community logos
import github_logo from '../../assets/logo/github-mark.png';
import docker_hub_logo from '../../assets/logo/docker-mark-blue.png';
import gradio_logo from '../../assets/logo/gradio-icon-logo.png';

const Sidebar = () => {

    const location = useLocation();

    const styles = {
        primaryListText: {
            '& .MuiTypography-root': {
                fontSize: 17,
                fontWeight: 600
            }
        },
        activeLink: {
            color: theme.palette.primary.main
        },
        communityIcon: {
            width: '40px', // Increased size
            height: '40px', // Increased size
            objectFit: 'contain',
            margin: '0 10px'
        },
        communitySection: {
            marginTop: 'auto', // Pushes the section to the bottom
            padding: '20px 0',
            textAlign: 'center', // Centers the header
        },
        communityHeader: {
            fontWeight: 'bold',
            fontSize: '18px', // Larger header size
            marginBottom: '15px', // Adds distance from the icons
        }
    }

    const listItems = [
        {text: 'Home', icon:  <Home />, route: '/'},
        {text: 'About', icon:  <Info />, route: '/about'},
        {text: 'Toolkit', icon:  <MiscellaneousServices />, route: '/toolkit'},
        {text: 'Research', icon:  <AutoStories />, route: '/research'},
        {text: 'Documentation', icon:  <Article />, route: '/documentation'},
        {text: 'Contact', icon:  <ContactSupport />, route: '/contact'}
    ];

    const communityItems = [
        {name: 'GitHub', logo: github_logo, link: 'https://github.com/iNoBo', alt: 'GitHub Logo'},
        {name: 'Docker Hub', logo: docker_hub_logo, link: 'https://hub.docker.com/u/intelligencenoborders', alt: 'Docker Hub Logo'},
        {name: 'Hugging Face', logo: gradio_logo, link: 'https://huggingface.co/iNoBo', alt: 'Hugging Face Logo'},
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Toolbar>
                <Link to="/"><img width={'100%'} height={50} src={logo} alt={'Logo'}/></Link>
            </Toolbar>
            <List>
                {listItems.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton
                            component={Link}
                            to={item.route}
                            selected={location.pathname === item.route}
                        >
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} sx={styles.primaryListText}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            {/* Community Section */}
            <Box sx={styles.communitySection}>
                <Typography variant="h6" sx={styles.communityHeader}>
                    Community
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {communityItems.map((item) => (
                        <a key={item.name} href={item.link} target="_blank" rel="noopener noreferrer">
                            <img src={item.logo} alt={item.alt} style={styles.communityIcon} />
                        </a>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default Sidebar;
