import React, { useEffect, useRef, useState } from 'react';
import Layout from "../../layouts/Layout";
import { Box, Typography, Grid, Paper, Button, Divider } from "@mui/material";
import theme from "../../theme/AppTheme";

const publications = [
    {
        title: "SciNoBo: A Hierarchical Multi-Label Classifier of Scientific Publications",
        authors: "Nikolaos Gialitsis, Sotiris Kotitsas, and Haris Papageorgiou",
        year: "2022",
        source: "Companion Proceedings of the Web Conference 2022 (WWW '22)",
        publisher: "Association for Computing Machinery, New York, NY, USA, 800–809.",
        link: "https://doi.org/10.1145/3487553.3524677"
    },
    {
        title: "SCINOBO: a novel system classifying scholarly communication in a dynamically constructed hierarchical Field-of-Science taxonomy",
        authors: "Kotitsas S, Pappas D, Manola N, Papageorgiou H.",
        year: "2023",
        source: "Frontiers in Research Metrics and Analytics",
        publisher: "Frontiers Media",
        additionalInfo: "PMID: 37215249; PMCID: PMC10192702.",
        link: "https://doi.org/10.3389/frma.2023.1149834"
    },
    {
        title: "Empowering Knowledge Discovery from Scientific Literature: A novel approach to Research Artifact Analysis",
        authors: "Stavropoulos, P., Lyris, I., Manola, N., Grypari, I., & Papageorgiou, H.",
        year: "2023",
        source: "Proceedings of the 3rd Workshop for Natural Language Processing Open Source Software",
        publisher: "Association for Computational Linguistics",
        link: "https://aclanthology.org/2023.nlposs-1.5"
    },
    {
        title: "Leveraging fine-tuned Large Language Models with LoRA for Effective Claim, Claimer, and Claim Object Detection",
        authors: "Sotiris Kotitsas, Panagiotis Kounoudis, Eleni Koutli, Haris Papageorgiou",
        year: "2024",
        source: "EACL, Malta",
        publisher: "Association for Computational Linguistics",
        link: "https://aclanthology.org/2024.eacl-long.156"
    }
];

const Research = () => {
    const titleRefs = useRef([]);
    const [maxTitleHeight, setMaxTitleHeight] = useState(0);

    useEffect(() => {
        // Calculate the maximum height needed for titles
        const heights = titleRefs.current.map(ref => ref.clientHeight);
        const max = Math.max(...heights);
        setMaxTitleHeight(max);
    }, []);

    return (
        <Layout>
            <Box mb={3}>
                <Typography variant="h4" color="primary" textAlign="center">
                    Our Scientific Work
                </Typography>
            </Box>
            <Box mb={3}>
                <Typography variant="h5" color="primary" textAlign="center">
                    Research Publications
                </Typography>
            </Box>
            <Grid container spacing={3}>
                {publications.map((pub, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper
                            elevation={3}
                            sx={{
                                padding: theme.spacing(3),
                                minHeight: '450px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    height: `${maxTitleHeight}px`,
                                    overflow: 'hidden',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                    sx={{ textAlign: 'center', lineHeight: 1.4 }}
                                    ref={el => (titleRefs.current[index] = el)}
                                >
                                    {pub.title}
                                </Typography>
                            </Box>
                            <Divider sx={{ marginY: theme.spacing(1) }} />
                            <Box>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{ textAlign: 'left', marginBottom: theme.spacing(1) }}
                                >
                                    <strong>Authors:</strong> {pub.authors}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{ textAlign: 'left', marginBottom: theme.spacing(1) }}
                                >
                                    <strong>Year:</strong> {pub.year}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{ textAlign: 'left', marginBottom: theme.spacing(1) }}
                                >
                                    <strong>Source:</strong> {pub.source}
                                </Typography>
                                {pub.publisher && (
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ textAlign: 'left', marginBottom: theme.spacing(1) }}
                                    >
                                        <strong>Publisher:</strong> {pub.publisher}
                                    </Typography>
                                )}
                                {pub.additionalInfo && (
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ textAlign: 'left', marginBottom: theme.spacing(1) }}
                                    >
                                        <strong>Additional Information:</strong> {pub.additionalInfo}
                                    </Typography>
                                )}
                            </Box>
                            <Box mt={2} textAlign="center">
                                <Button
                                    variant="contained"
                                    color={pub.link ? 'primary' : 'grey'}
                                    href={pub.link || '#'}
                                    target={pub.link ? '_blank' : ''}
                                    rel={pub.link ? 'noreferrer' : ''}
                                    sx={{ width: '100%' }}
                                    disabled={!pub.link}
                                >
                                    {pub.link ? 'Read' : 'Unavailable'}
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Layout>
    );
};

export default Research;
