import React from 'react';
import { Box, Typography } from '@mui/material';

import Layout from "../../layouts/Layout";
import CardSearch from "../../components/cards/CardSearch";
const ClaimVerification = () => {
    return (
        <>
            <Layout sx={{height: '100vh'}}>
                <Box marginTop={12}>
                    <Box marginBottom={3}>
                        <Typography variant={'h4'} sx={{fontSize: 30, color: '#647684'}}>I want to perform claim verification in a news article by providing the article</Typography>
                    </Box>
                    <CardSearch text={'Please provide the claim of the news article that you want to verify'} pageType={'claim_verification'}/>
                </Box>
            </Layout>
        </>
    );
};
export default ClaimVerification;
