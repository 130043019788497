import React, { useState, useEffect } from 'react';
import {
    Box,
    Divider,
    Typography,
    IconButton,
    Tooltip,
    CircularProgress,
    Backdrop
} from "@mui/material";
import { Assignment, Check, ContentCopy, EditNote, ThumbUpAlt, ThumbUpOffAlt, ThumbDownAlt, ThumbDownOffAlt } from '@mui/icons-material';
import { useNavigate, useLocation, Navigate } from "react-router-dom";

import Layout from "../../layouts/Layout";
import theme from "../../theme/AppTheme";
import SnackbarCopy from "../../components/snackbars/SnackbarCopy";
import ResultsAccordion from "../../components/accordion/ResultsAccordion";
import SearchField from "../../components/fields/SearchField";

const Response = () => {

    const [isLiked, setLiked] = useState(false);
    const [isDisliked, setDisliked] = useState(false);
    const [isCopied, setCopied] = useState(false);
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [apiData, setApiData] = useState([]);
    const [pageType, setPageType] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [notFound, setNotFound] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let notFoundTimeout;
        const fetchData = async () => {
            const data = location.state?.data;
            const receivedPageType = location.state?.pageType;
			const initialSearchText = location.state?.searchText || '';

            setSearchText(initialSearchText);

            if (data && receivedPageType) {
                try {
					// Check for an error message in the response
                    if (data.message && data.success === 0) {
                        setNotFound(true);
                        setLoading(false);

                    /* // Check if claim_objects are empty
                    if (Array.isArray(data.claim_objects) && data.claim_objects.length === 0) {
                        setNotFound(true);
                        setLoading(false); */

                        // Redirect to response after a few seconds
                        notFoundTimeout = setTimeout(() => {
                            setNotFound(false);
                            navigate('/response');
                        }, 2000);

                        return;
                    }

                    /* // Check if no_info, refute & support objects are empty
                    if (
                        (Array.isArray(data.no_info) && data.no_info.length === 0) &&
                        (Array.isArray(data.refute) && data.refute.length === 0) &&
                        (Array.isArray(data.support) && data.support.length === 0)
                    ) {
                        setNotFound(true);
                        setLoading(false);

                        // Redirect to response after a few seconds
                        notFoundTimeout = setTimeout(() => {
                            setNotFound(false);
                        }, 2000);

                        return;
                    }

                    await new Promise((resolve) => setTimeout(resolve, 500000)); */	   
                    setApiData(data);
                    setPageType(receivedPageType);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            } else {
				// Set loading to false before navigating to response													 
                setLoading(false);
                setNotFound(true);

				// Show loader for a few seconds before navigating to response															  
                notFoundTimeout = setTimeout(() => {
                    setNotFound(false);
                    navigate('/response');
                }, 2000);
            }
        };

        fetchData();

		// Cleanup function to clear timeouts
        return () => {
            clearTimeout(notFoundTimeout);
        };
    }, [location.state, navigate]);

    const handleCopy = () => {
        const data = location.state?.data;
        const claim_obj = data?.claim_objects[0]?.claim; // handle possible undefined objects
        const specificText = claim_obj;

        if (specificText) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(specificText)
                    .then(() => {
                        setCopied(true);
						// Reset isCopied after 5 seconds
                        setTimeout(() => {
                            setCopied(false);
                        }, 4000);
                    })
                    .catch(error => {
                        console.error('Failed to copy:', error);
                    });
            } else {
				// In case it is not supported from specific browser
                console.error('This action is not supported.');
            }
        } else {
            // Handle the case when specificText is empty
            console.error('Specific text is empty. Cannot copy.', data?.claim_objects[0]?.claim);
        }
    };

    const handleNewQuery = () => {
        navigate('/');
    };

    if (notFound) {
        return <Navigate to="/response" />;
    }

    const handleLike = () => {
        isLiked ? setLiked(false) : setLiked(true);
        setDisliked(false);
    };
 
     const handleDislike = () => {
         isDisliked ? setDisliked(false) : setDisliked(true);
         setLiked(false);
    };

    const styles = {
        iconBtnCopy: {
            padding: 1,
            color: theme.palette.text.secondary,
            backgroundColor: 'transparent',
            '& .MuiSvgIcon-root': {
                '& path': {
                    fill: theme.palette.text.secondary,
                    width: 24,
                    height: 24,
                }
            },
            '&:hover': {
                backgroundColor: theme.palette.background.default
            }
        }
    };

    return (
        <Layout hasFab>
            <Box marginTop={8}>
                <Box sx={{ marginBottom: 7 }} />
                <SearchField searchText={searchText} setSearchText={setSearchText} setLoading={setLoading} loading={loading} />
                <Box sx={{ marginBottom: 7 }} />
                <Box>
                    <Box display={'flex'} alignItems={'center'} mb={4}>
                        <Box mr={1} sx={{ marginTop: '3px' }}>
                            <Assignment color={'primary'} />
                        </Box>
                        <Typography variant={'h5'} sx={{ color: theme.palette.primary.light, fontWeight: 500 }}>Answer</Typography>
                    </Box>
                    <Box mb={4}>
                        <ResultsAccordion title={"Query Decomposition"}>
                            <Box mb={5}>
                                <Typography variant={'body1'} color={'textPrimary'} component={'div'}>
                                    {loading ? (
                                        <CircularProgress />
                                    ) : apiData.length === 0 ? (
                                        'Something went wrong. Please try again.'
                                    ) : (
                                        <div>
                                            <h3>Tasks</h3>
                                            {Array.isArray(apiData.tasks) && apiData.tasks.map((task, index) => (
                                                <div key={index}>
                                                    <ul>
                                                        <li>{task}</li>
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </Typography>
                            </Box>
                        </ResultsAccordion>
                    </Box>
                    <Divider />
                    <Box mt={2} display={'flex'} alignItems={'center'}>
                        <Box mr={1}>
                            <Tooltip title="Copy to clipboard">
                                <IconButton onClick={handleCopy} sx={styles.iconBtnCopy}>
                                    {isCopied ? <Check /> : <ContentCopy />}
                                </IconButton>
                            </Tooltip>
                            <SnackbarCopy isCopied={isCopied} />
                        </Box>
                        <Box>
                            <Tooltip title="New query">
                                <IconButton onClick={handleNewQuery} sx={styles.iconBtnCopy}>
                                    <EditNote />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box>
                            <Tooltip title="Thumbs up">
                                <IconButton onClick={handleLike} sx={styles.iconBtnCopy}>
                                    {isLiked ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box>
                            <Tooltip title="Thumbs down">
                                <IconButton onClick={handleDislike} sx={styles.iconBtnCopy}>
                                    {isDisliked ? <ThumbDownAlt /> : <ThumbDownOffAlt />}
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
};

export default Response;
