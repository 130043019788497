import React from 'react';
import {Box, Drawer} from '@mui/material';

import Sidebar from "../components/sidebar/Sidebar";
import FabButton from "../components/buttons/FabButton";

const drawerWidth = 240;
const Layout = ({children, hasFab}) => {

    return (
        <Box sx={{ display: 'flex' }}>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="list-items"
            >
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    <Sidebar />
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    // height: '100vh',
                    position: 'relative'
                }}
            >
                {children}
            </Box>
            {hasFab && (
                <Box position={'fixed'} right={48} bottom={48}>
                    <FabButton />
                </Box>
            )}
        </Box>
    );
};
export default Layout;
