import React, { useState, useRef } from 'react';
import {Fab, Menu, MenuItem} from '@mui/material';
import { Add } from '@mui/icons-material';
import {Link } from "react-router-dom";
import theme from "../../theme/AppTheme";
const FabButton = () => {

    const menuRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const styles =  {
        fabMenu: {
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            '& .MuiMenuItem-root': {
                color: theme.palette.grey[800],
                fontSize: 15,
            }
        },
    }

    return (
        <>
            <Fab
                color="primary"
                aria-controls="fab-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size={'medium'}
            >
                <Add />
            </Fab>
            <Menu
                id="fab-menu"
                aria-labelledby="fab-menu"
                ref={menuRef}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                onClose={handleClose}
                MenuListProps={{sx: styles.fabMenu}}
            >
                <MenuItem
                    component={Link}
                    to={'/claim-analysis'}
                    disableRipple
                >
                    Perform a new claim analysis
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/claim-verification'}
                    disableRipple
                >
                    Perform a claim verification
                </MenuItem>
            </Menu>
        </>
    )
};
export default FabButton;