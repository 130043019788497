					
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Layout from "../../layouts/Layout";

const LiveDemoComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const HFSpace = searchParams.get("HFSpace");

    return (
        <>
            <Helmet>
                <script type="module" crossorigin="" src="https://gradio.s3-us-west-2.amazonaws.com/4.32.2/gradio.js"></script>
            </Helmet>
            <Layout>
                <gradio-app 
                    src={HFSpace}
                    autoscroll="true"
                    theme_mode="light">
                </gradio-app>
            </Layout>
        </>
    );
};

export default LiveDemoComponent;
