import React from 'react';
import { Alert, Snackbar } from '@mui/material';
const SnackbarError = ({open, handleCloseSnackbar}) => {
    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert severity="error">
                    Please enter a valid search text
                </Alert>
            </Snackbar>
        </>
    )
};
export default SnackbarError;