import React, { useState } from 'react';
import {Card, Box, Typography, TextField, Button} from '@mui/material';
import {useNavigate} from "react-router-dom";
import { fetchNewsAnalysis, fetchClaimVerification } from '../../apis/api';
import { fetchTextSimplification } from '../../apis/dfkiapi';
import SnackbarError from "../snackbars/SnackbarError";
const CardSearch = ({text, pageType, data}) => {

    const [searchText, setSearchText] = useState('');
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const handleSkipClick = () => {
        navigate('/');
    };
    const handleSearchClick = async () => {
        if (!searchText.trim()) {
            setError('Please enter a valid search text.');
            return;
        }

        try {
            let fetchDataFunction;
            alert('search')
            // Determine which fetch function to use based on the pageType
            if (pageType === 'news_analysis') {
                fetchDataFunction = fetchNewsAnalysis;
            } else if (pageType === 'claim_verification') {
                fetchDataFunction = fetchClaimVerification;
            } else if (pageType === 'text_simplification') {
                fetchDataFunction = fetchTextSimplification;
            } else {
                console.error('Invalid pageType:', pageType);
                return;
            }

            // Fetching data based on the pageType
            const responseData = await fetchDataFunction({
                doc_id: '1',
                text: searchText,
                auth_key: 'ATC_ARC_2023_demo@unbiased',
            });

            // Handle the fetched data as needed
            //console.log('Response Data:', responseData);
            if (pageType === 'text_simplification') {
                navigate('/response-ts', { state: { data: responseData, pageType } });
            }

        } catch (error) {
            console.error('Error handling search:', error);
            navigate('/response');
        }
    };
    const handleCloseError = () => {
        setError(null);
    };

    return (
        <>
            <Card>
           <Box mb={3}>
               <Typography variant={'h6'} sx={{fontWeight: 500, color: '#545454'}}>{text}</Typography>
           </Box>
           <Box mb={6}>
               <TextField
                   variant={'filled'}
                   label={''}
                   fullWidth
                   value={searchText}
                   onChange={(e) => setSearchText(e.target.value)}
                   error={!!error}
                   InputProps={{
                       type: 'search',
                       onKeyDown: (e) => {
                           if (e.key === 'Enter') {
                               handleSearchClick(searchText);
                           }
                       },
                   }}
               />
           </Box>
           <Box display={'flex'} justifyContent={'end'}>
               <Box mr={2}>
                   <Button
                       variant={'text'}
                       color={'secondary'}
                       onClick={handleSkipClick}
                   >
                       Skip
                   </Button>
               </Box>
               <Button
                   variant={'contained'}
                   color={'primary'}
                   onClick={handleSearchClick}
               >
                   Search
               </Button>
           </Box>
       </Card>
            <SnackbarError open={!!error} handleCloseSnackbar={handleCloseError}/>
        </>
    );
};
export default CardSearch;
